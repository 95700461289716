import { get, toUpper } from 'lodash'

import { ENVIRONMENT } from '../utils/enums'
import { getReq } from '../utils/request'

import { ENV_LOAD_START, ENV_LOAD_DONE, ENV_LOAD_FAIL, SETTINGS_LOAD_START, SETTINGS_LOAD_DONE, SETTINGS_LOAD_FAIL } from '../types/settings'

export const loadEnvironment = () => {
	return async (dispatch) => {
		dispatch({
			type: ENV_LOAD_START
		})

		try {
			const settings = await getReq('/api/v0/system/prostredie')
			dispatch({
				type: ENV_LOAD_DONE,
				payload: {
					env: toUpper(get(settings, 'response.content', ENVIRONMENT.TEST))
				}
			})
		} catch (e) {
			dispatch({
				type: ENV_LOAD_FAIL
			})
		}
	}
}

export const loadSettings = () => {
	return async (dispatch) => {
		dispatch({
			type: SETTINGS_LOAD_START
		})

		try {
			const settings = await getReq('/api/v0/nastavenia')
			dispatch({
				type: SETTINGS_LOAD_DONE,
				payload: {
					settings
				}
			})
			return settings
		} catch (e) {
			dispatch({
				type: SETTINGS_LOAD_FAIL
			})
		}
	}
}
