import { get } from 'lodash'
import { ENV_LOAD_START, ENV_LOAD_DONE, ENV_LOAD_FAIL, SETTINGS_LOAD_START, SETTINGS_LOAD_DONE, SETTINGS_LOAD_FAIL } from '../types/settings'

import { ENVIRONMENT } from '../utils/enums'

export const initState = {
	settings: {
		env: ENVIRONMENT.TEST,
		isFailure: false,
		isLoading: false,
		isLoaded: false
	},
	userSettings: {
		data: {},
		isFailure: false,
		isLoading: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case ENV_LOAD_START:
			return {
				...state,
				settings: {
					...state.settings,
					isLoading: true,
					isFailure: false
				}
			}
		case ENV_LOAD_DONE:
			return {
				...state,
				settings: {
					...state.settings,
					env: get(action, 'payload.env'),
					isLoading: false,
					isFailure: false,
					isLoaded: true
				}
			}
		case ENV_LOAD_FAIL:
			return {
				...state,
				settings: {
					...initState.settings,
					isLoading: false,
					isFailure: true
				}
			}
		case SETTINGS_LOAD_START:
			return {
				...state,
				userSettings: {
					...state.userSettings,
					isLoading: true
				}
			}
		case SETTINGS_LOAD_DONE:
			return {
				...state,
				userSettings: {
					...state.userSettings,
					data: get(action, 'payload.settings'),
					isLoading: false,
					isFailure: false
				}
			}
		case SETTINGS_LOAD_FAIL:
			return {
				...state,
				userSettings: {
					...initState.userSettings,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
