import React from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import { initialize, reset } from 'redux-form'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
// actions
import { get, find } from 'lodash'
import * as AuthActions from '../actions/AuthActions'
import * as SettingsActions from '../actions/SettingsActions'
// resources
import WidgetModal from './Menu/Widgety'
import NastaveniaModal from './Menu/Nastavenia'

import { getAccessToken } from '../utils/auth'
import { putReq, getReq } from '../utils/request'
import { FORMS } from '../utils/enums'

class UserDropdownMenu extends React.Component {
	static propTypes = {
		authActions: PropTypes.shape({
			logoutUser: PropTypes.func.isRequired,
			changeBusinessChannel: PropTypes.func.isRequired,
			userMiestoPodpisChanged: PropTypes.func.isRequired
		}).isRequired,
		settingsActions: PropTypes.shape({
			loadSettings: PropTypes.func.isRequired
		}).isRequired,
		widgetyActions: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		podpisMiesto: PropTypes.string
	}

	state = {
		visible: false,
		showWidgetModal: false,
		showNastaveniaModal: false,
		podpisMiesto: this.props.podpisMiesto
	}

	toggle = () => {
		const { visible } = this.state

		this.setState({
			visible: !visible
		})
	}

	toggleNastaveniaModal = (showNastaveniaModal) => {
		const { podpisMiesto, dispatch, userSettings } = this.props
		const biznisKanaly = get(userSettings, 'biznisKanaly', [])

		dispatch(
			initialize(
				FORMS.NASTAVENIA,
				{
					podpisMiesto,
					biznisKanal: get(biznisKanaly, '[0].id')
				},
				true
			)
		)
		dispatch(reset(FORMS.NASTAVENIA))

		this.setState({
			showNastaveniaModal
		})
	}

	handleClickOutside() {
		this.setState({
			visible: false
		})
	}

	submitNastavenia = async (values) => {
		try {
			const { ciselniky, authActions, settingsActions } = this.props

			const biznisKanal = find(get(ciselniky, 'kanal', []), (kanal) => get(kanal, 'id') == get(values, 'biznisKanal'))

			await putReq('/api/v0/nastavenia', null, {
				biznisKanal,
				podpisMiesto: get(values, 'podpisMiesto')
			})

			authActions.changeBusinessChannel(biznisKanal)
			authActions.userMiestoPodpisChanged(get(values, 'podpisMiesto'))
			settingsActions.loadSettings()

			this.toggleNastaveniaModal(false)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	render() {
		const { authActions, t } = this.props
		const { visible, showWidgetModal, showNastaveniaModal } = this.state

		return (
			<div className='menu-toggle'>
				<span onClick={this.toggle} />
				<div className={cx('dropdown', 'header-dropdown', { shown: visible })}>
					<ul>
						<li>
							<a
								onClick={() => {
									this.setState({ showWidgetModal: true })
									this.toggle()
								}}
							>
								{t('components:UserDropdownMenu.Widgety')}
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									this.toggleNastaveniaModal(true)
									this.toggle()
								}}
							>
								{t('components:UserDropdownMenu.Nastavenia')}
							</a>
						</li>
						<li>
							<Link to={`/api/v0/pomocnik/menu?accessToken=${getAccessToken()}`} target='_blank' rel='noopener noreferrer'>
								{t('components:UserDropdownMenu.Pomocník')}
							</Link>
						</li>
						<li>
							<a onClick={authActions.logoutUser}>{t('components:UserDropdownMenu.Odhlásiť')}</a>
						</li>
						<li>
							<span className='version'>
								{t('components:UserDropdownMenu.Verzia')} {process.env.REACT_APP_VERSION}
							</span>
						</li>
					</ul>
				</div>
				<WidgetModal toggleWidgetModal={(showWidgetModal) => this.setState({ showWidgetModal })} shown={showWidgetModal} />
				<NastaveniaModal
					toggleNastaveniaModal={this.toggleNastaveniaModal}
					shown={showNastaveniaModal}
					onSubmit={(values) => this.submitNastavenia(values)}
				/>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	authActions: bindActionCreators(AuthActions, dispatch),
	settingsActions: bindActionCreators(SettingsActions, dispatch)
})

const mapStateToProps = (state) => ({
	podpisMiesto: get(state, 'auth.user.podpisMiesto'),
	ciselniky: get(state, 'ciselniky.data'),
	userSettings: get(state, 'settings.userSettings.data')
})

export default withTranslation('components')(connect(mapStateToProps, mapDispatchToProps)(onClickOutside(UserDropdownMenu)))
